<template>
  <div class="blog-manager">
    <h2>Управление постами в блоге</h2>
    <form @submit.prevent="addPost">
      <input type="text" v-model="title" placeholder="Название поста" required />
      <textarea v-model="subtitle" placeholder="Контент" required></textarea>
      <div class="file-upload">
        <input type="file" @change="handleMainMediaUpload" id="main-media" class="file-input" required />
        <label for="main-media" class="file-label">
          <span class="file-button">Выберите главное фото или видео</span>
          <span class="file-name">{{ mainFileName || "Файл не выбран" }}</span>
        </label>
      </div>
      <div class="file-upload">
        <input type="file" @change="handleFileUpload" id="files" class="file-input" multiple />
        <label for="files" class="file-label">
          <span class="file-button">Выберите другие фото</span>
          <span class="file-name">{{ fileNames.length > 0 ? fileNames.join(', ') : "Файлы не выбраны" }}</span>
        </label>
      </div>
      <div class="select-model">
        <select v-model="selectedModelId" required>
          <option disabled value="">Выберите модель</option>
          <option v-for="model in models" :key="model.id" :value="model.id">{{ model.name }}</option>
        </select>
      </div>
      <button type="submit">Добавить пост</button>
    </form>
    <ul>
      <li v-for="post in posts.sort((a,b) => b.createdAt - a.createdAt)" :key="post.id">
        <h3>{{ post.title }}</h3>
        <p>{{ post.subtitle }}</p>
        <p v-if="post.model">Модель: {{ post.model.name }}</p>
        <div v-if="post.mainMediaUrl">
          <img v-if="post.mainMediaType === 'image'" :src="post.mainMediaUrl" alt="Main Post Media" />
          <video v-if="post.mainMediaType === 'video'" :src="post.mainMediaUrl" autoplay muted loop></video>
        </div>
        <div v-if="post.imageUrls && post.imageUrls.length">
          <img v-for="url in post.imageUrls" :src="url" :key="url" alt="Post Image" />
        </div>
        <button @click="deletePost(post.id, post.imageNames, post.mainMediaName)">Удалить</button>
      </li>
    </ul>
  </div>
</template>

<script>
import { auth, db, storage } from "@/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, getDocs, deleteDoc, doc, Timestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { models } from "@/data/modelsData"; // Импорт моделей

export default {
  data() {
    return {
      title: "",
      subtitle: "",
      posts: [],
      email: null,
      mainMediaFile: null,
      mainFileName: "",
      mainMediaType: "",
      imageFiles: [],
      fileNames: [],
      models, // Добавляем модели в данные компонента
      selectedModelId: "" // Добавляем переменную для выбранной модели
    };
  },
  async created() {
    try {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.email = user.email;
        } else {
          this.email = null;
        }
      });
      const querySnapshot = await getDocs(collection(db, "blogPosts"));
      this.posts = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Ошибка при получении постов из блога:", error);
    }
  },
  methods: {
    handleMainMediaUpload(event) {
      const file = event.target.files[0];
      this.mainMediaFile = file;
      this.mainFileName = file.name;
      this.mainMediaType = file.type.startsWith('image/') ? 'image' : 'video';
    },
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      this.imageFiles = files;
      this.fileNames = files.map(file => file.name);
    },
    async addPost() {
      try {
        const imageUrls = [];
        const imageNames = [];
        
        // Загрузка главного медиа в Firebase Storage
        const mainMediaRef = ref(storage, `blogMedia/${this.mainMediaFile.name}`);
        await uploadBytes(mainMediaRef, this.mainMediaFile);
        const mainMediaUrl = await getDownloadURL(mainMediaRef);
        const mainMediaName = this.mainMediaFile.name;
        
        // Загрузка остальных изображений в Firebase Storage
        for (const file of this.imageFiles) {
          const storageRef = ref(storage, `blogImages/${file.name}`);
          await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(storageRef);
          imageUrls.push(imageUrl);
          imageNames.push(file.name);
        }
        
        // Поиск выбранной модели
        const selectedModel = this.models.find(model => model.id === this.selectedModelId);
        
        // Добавление поста в Firestore
        const docRef = await addDoc(collection(db, "blogPosts"), {
          title: this.title,
          subtitle: this.subtitle,
          mainMediaUrl,
          mainMediaName,
          mainMediaType: this.mainMediaType,
          imageUrls,
          imageNames,
          createdAt: Timestamp.now(),
          author: this.email,
          model: selectedModel // Привязываем модель к посту
        });
        
        this.posts.push({ id: docRef.id, title: this.title, subtitle: this.subtitle, mainMediaUrl, mainMediaName, mainMediaType: this.mainMediaType, imageUrls, imageNames, model: selectedModel });
        this.title = "";
        this.subtitle = "";
        this.mainFileName = "";
        this.mainMediaFile = null;
        this.mainMediaType = "";
        this.fileNames = [];
        this.imageFiles = [];
        this.selectedModelId = "";
      } catch (error) {
        console.error("Ошибка при добавлении поста:", error);
      }
    },
    async deletePost(id, imageNames, mainMediaName) {
      try {
        // Получение всех постов из Firestore
        const querySnapshot = await getDocs(collection(db, "blogPosts"));
        const allPosts = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        
        // Удаление главного медиа из Firebase Storage, если оно не используется в других постах
        const isMainMediaUsedInOtherPosts = allPosts.some(
          (post) => post.id !== id && post.mainMediaName === mainMediaName
        );
        if (!isMainMediaUsedInOtherPosts) {
          const mainMediaRef = ref(storage, `blogMedia/${mainMediaName}`);
          await deleteObject(mainMediaRef);
        }
        
        // Удаление остальных изображений из Firebase Storage, если они не используются в других постах
        for (const imageName of imageNames) {
          const isUsedInOtherPosts = allPosts.some(
            (post) => post.id !== id && (post.imageNames.includes(imageName) || post.mainMediaName === imageName)
          );
          if (!isUsedInOtherPosts) {
            const imageRef = ref(storage, `blogImages/${imageName}`);
            await deleteObject(imageRef);
          }
        }
        
        // Удаление поста из Firestore
        await deleteDoc(doc(db, "blogPosts", id));
        this.posts = this.posts.filter((post) => post.id !== id);
      } catch (error) {
        console.error("Ошибка при удалении поста:", error);
      }
    }
  }
};
</script>


<style scoped>
.blog-manager {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f4f4f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}
h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}
form {
  margin-bottom: 20px;
}
input, textarea, select {
  display: block;
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
select {
  background-color: white;
  color: #666;
}
select:focus {
  outline: none;
  border-color: #007bff;
}
button {
  display: block;
  width: calc(100% - 20px);
  padding: 12px 24px;
  margin: 20px auto;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
button:hover {
  background-color: #0056b3;
}
button:focus {
  outline: none;
}
button:active {
  background-color: #003f7f;
}
.file-upload {
  position: relative;
  display: block;
  width: calc(100% - 20px);
  margin: 10px auto;
}
.file-input {
  display: none;
}
.file-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}
.file-button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.file-button:hover {
  background-color: #0056b3;
}
.file-name {
  margin-left: 10px;
  color: #666;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
h3 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #333;
}
p {
  margin-left: 10px;
  text-align: left;
  font-size: 16px;
  color: #666;
}
img {
  width: 100%;
}
video {
  width: 100%;
}
</style>
