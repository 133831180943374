import axios from 'axios';

const botToken = '7401867522:AAEffG_CGFTCJnKjFO5axLXw8f_Z_ObRykU';
const chatId = '-1002459231381';

export function sendMessage(message) {
  const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
  return axios.post(url, {
    chat_id: chatId,
    text: message,
  });
}

export function sendPhoto(file, caption) {
  const formData = new FormData();
  formData.append('photo', file);
  formData.append('chat_id', chatId);
  if (caption) {
    formData.append('caption', caption);
  }

  const url = `https://api.telegram.org/bot${botToken}/sendPhoto`;
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}