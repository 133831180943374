<template>
  <div class="card" @click="navigateToDetails">
    <div class="image-container">
      <img v-if="blog.mainMediaType === 'image'" :src="blog.mainMediaUrl" :alt="blog.title" />
      <video v-if="blog.mainMediaType === 'video'" :src="blog.mainMediaUrl" autoplay muted loop></video>
    </div>
    <div class="card-content">
      <h3>{{ blog.title }}</h3>
      <p>{{ blog.subtitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogCard",
  props: {
    blog: {
      type: Object,
      required: true,
    },
  },
  methods: {
    navigateToDetails() {
      this.$router.push(`/blog/${this.blog.id}`);
    },
  },
};
</script>

<style scoped>
/* Карточка блога */
.card {
  position: relative;
  width: 100%;
  max-width: 100%; /* Гарантируем, что карточка не выйдет за пределы */
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 20px; /* Минимизируем отступы */
  box-sizing: border-box; /* Включаем padding и border в размеры карточки */
}

/* Изображение */
.image-container {
  width: 100%;
  height: 0;
  padding-top: 150%; /* Соотношение сторон 2:3 */
  position: relative;
  overflow: hidden;
}
.image-container video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Заполняет карточку изображением */
  transition: transform 0.3s ease;
}
.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Заполняет карточку изображением */
  transition: transform 0.3s ease;
}
.card:hover .image-container video {
  transform: scale(1.05); /* Легкое увеличение при наведении */
}
.card:hover .image-container img {
  transform: scale(1.05); /* Легкое увеличение при наведении */
}

/* Содержимое карточки */
.card-content {
  text-align: center;
  padding: 10px; /* Уменьшение отступов */
  box-sizing: border-box; /* Учитываем padding в размерах */
}

.card-content h3 {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0 5px;
  color: #444;
  text-transform: uppercase;
}

.card-content p {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  color: #777;
  margin: 0;
  font-weight: 300;
}

/* Адаптивность */
@media (max-width: 768px) {
  .card {
    max-width: 100%; /* Оставляем карточку в рамках */
    margin: 0 auto; /* Центрируем карточку */
  }

  .card-content h3 {
    font-size: 18px;
  }

  .card-content p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .card {
    margin: 0 auto 15px; /* Центрируем карточку и добавляем отступ снизу */
  }

  .card-content h3 {
    font-size: 12px;
  }

  .card-content p {
    font-size: 10px;
  }
}
</style>
