<template>
  <div class="home">
    <!-- Логотип с анимацией -->
    <div v-if="showLogo" class="logo-container">
      <img src="@/assets/1.svg" alt="Future Face Logo" class="logo-image" />
    </div>

    <!-- Основной контент -->
    <div v-else class="content">
      <!-- Заголовок страницы -->
      <header class="header">
        <div class="page-title-container">
          <img
            src="@/assets/1.svg"
            alt="Future Face"
            class="page-title-logo"
            @click="openMenu"
          />
        </div>
      </header>

      <!-- Переключатель языка -->
      <div class="language-switcher">
        <!-- aria-label для улучшения a11y -->
        <button @click="toggleLanguage" aria-label="Toggle language">
          <span :class="{ active: currentLanguage === 'Eng' }">Eng</span>/
          <span :class="{ active: currentLanguage === 'Rus' }">Rus</span>
        </button>
      </div>

      <!-- Поле поиска под переключателем языка -->
      <div class="search-container">
        <input
          class="search-bar"
          type="text"
          :placeholder="currentLanguage === 'Eng' ? 'Search' : 'Поиск'"
          v-model="searchQuery"
          aria-label="Search input"
        />
      </div>

      <!-- Секция блога -->
      <div class="blog-section">
        <!-- <div class="blog-cardfirst">
          <BlogCardFirst/>
        </div> -->
        <!-- Если блогов нет, покажем надпись -->
        <div class="blog-list" v-if="filteredBlogs.length > 0">
          <BlogCard
            v-for="blog in filteredBlogs"
            :key="blog.id"
            :blog="blog"
          />
        </div>
        <p v-else class="no-blogs">
          {{ currentLanguage === 'Eng' ? 'No blogs found.' : 'Нет записей.' }}
        </p>
      </div>
    </div>
     <!-- Footer -->
     <footer class="footer">
      <p>&copy; {{ new Date().getFullYear() }} Saint Faces Agency. All rights reserved.</p>
    </footer>
  </div>
  
</template>

<script>
import BlogCard from "@/components/BlogCard.vue";
import { getDocs, collection } from "firebase/firestore";
//import { blogs } from "@/data/blogData.js";
import { db } from "@/firebaseConfig";

export default {
  name: "HomePage",
  components: {
    BlogCard,
    //BlogCardFirst
  },
  data() {
    return {
      blogs: [],
      showLogo: true,
      searchQuery: "",
      currentLanguage: "Eng",
      footerVisible: false, // Состояние видимости футера
    };
  },
  computed: {
    filteredBlogs() {
      return this.blogs.filter((blog) => {
        const title = blog.title?.toLowerCase() || "";
        return title.includes(this.searchQuery.toLowerCase());
      });
    },
  },
  async mounted() {
    // Логотип скрываем через 2 секунды
    setTimeout(() => {
      this.showLogo = false;

      // Показываем футер с задержкой
      setTimeout(() => {
        this.footerVisible = true;
      }, 500); // Задержка перед показом футера
    }, 2000);
    try {
      const querySnapshot = await getDocs(collection(db, "blogPosts"));
      const blogs_sort = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      this.blogs = blogs_sort.sort((a,b) => {return b.createdAt - a.createdAt})
      console.log(this.blogs)
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  },
  methods: {
    openMenu() {
      this.$emit("toggle-menu");
    },
    toggleLanguage() {
      this.currentLanguage = this.currentLanguage === "Eng" ? "Rus" : "Eng";
    },
    goToInstagram() {
      window.open(
        "https://www.instagram.com/futurefacemgmt?igsh=azY0ZjY1cTUyMGds",
        "_blank"
      );
    },
  },
};
</script>


<!-- 
  Первый блок стилей (без scoped) — глобальные настройки.
  Лучше выносить их в отдельный файл (например, base.css/normalize.css),
  но для иллюстрации оставим здесь. 
-->
<style>
body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Убираем горизонтальную прокрутку */
  box-sizing: border-box;
  font-family: 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
*, *::before, *::after {
  box-sizing: inherit;
}
</style>

<style scoped>
/* Корневой контейнер */
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}

/* Логотип с анимацией */
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
  animation: fadeIn 1.5s ease-in-out, fadeOut 1.5s 1.5s ease-in-out forwards;
}

.logo-image {
  width: 350px; /* Задайте нужный размер */
  height: auto;
  animation: scaleUp 2s ease-in-out;
}

/* Контент, появляющийся после анимации логотипа */
.content {
  opacity: 0;
  transform: translateY(50px);
  animation: contentSlideUp 1.5s ease-in-out forwards;
  animation-delay: 1.5s;
  width: 100%;
}

/* Поле поиска */
.search-container {
  position: absolute;
  top: 65px;
  left: 30px;
  opacity: 0;
  animation: fadeInContent 1s ease-in-out forwards;
  animation-delay: 1.7s;
  z-index: 2; /* Устанавливаем z-index для правильного порядка наложения */
}

.search-bar {
  width: 100px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid rgb(5, 5, 5);
  padding-left: 10px;

  /* Плавная анимация изменения рамки при фокусе */
  transition: border-color 0.3s ease;
}

/* При фокусе можно немного менять цвет рамки */
.search-bar:focus {
  border-color: #666;
}

/* Заголовок страницы */
.header {
  text-align: center;
  margin: 20px 0;
  position: relative;
  opacity: 0;
  animation: fadeInContent 1s ease-in-out forwards;
  animation-delay: 1.5s;
}
.blog-cardfirst {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.page-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-title-logo {
  opacity: 0;
  display: block;
  margin: 0 auto;
  margin-top: -2px;
  width: 260px; /* подберите нужный размер */
  
  animation: fadeInContent 1s ease-in-out forwards;
  animation-delay: 1.5s;
  cursor: pointer;
}

/* Переключатель языка */
.language-switcher {
  position: absolute;
  top: 20px;
  left: 30px;
  opacity: 0;
  animation: fadeInContent 1s ease-in-out forwards;
  animation-delay: 1.7s;
}

.language-switcher button {
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  color: #0e0d0d;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  transition: color 0.3s ease;
}

.language-switcher button:hover {
  color: #666;
}

/* Секция блога */
.blog-section {
  padding: 30px 0px;
  width: 100%;
  background-color: #ffffff;
  min-height: 50vh;
}

.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 16px; /* Равные отступы между карточками */
  justify-items: center;
  padding: 0 10px; /* Убираем боковой скролл */
}

/* Сообщение, если нет записей */
.no-blogs {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
}
.footer {
  text-align: center;
  padding: 15px;
  background-color: #ffffff; /* Цвет фона футера */
  color: #333; /* Цвет текста */
  font-size: 14px;
  position: relative; /* По умолчанию */
  margin-top: 20px;
}

.footer p {
  margin: 0;
  line-height: 1.5;
}


/* Анимации */
@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes scaleUp {
  from { transform: scale(0.9); }
  to   { transform: scale(1); }
}

@keyframes contentSlideUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInContent {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@media (max-width: 1280px) {
  .blog-list{
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}
/* Адаптивность */
@media (max-width: 768px) {

  .page-title-logo {
    width: 190px; /* Уменьшенный размер логотипа */
    margin-top: -3px; /* Подкорректируйте отступ сверху, если нужно */
    transform: translateX(1px); /* Сдвигаем логотип вправо (по желанию) */
  }
  .blog-list {
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
    padding: 0 15px;
  }
  .logo-container {
    justify-content: center;
  }
  .logo-image {
    width: 400px;
    height: auto;
  }
  .search-container {
    position: static;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    left: auto; /* на всякий случай сбрасываем */
    top: auto;  /* сбрасываем */
  }
  .search-bar {
    width: 20%;
    font-size: 14px; /* при необходимости */
  }
  .language-switcher {
    top: 10px;
    left: 10px;
  }
  .language-switcher button {
    font-size: 12px;
    padding: 3px 5px;
  }
}

@media (max-width: 480px) {
  .page-title-logo {
    width: 170px;
    margin-top: 0px;
    transform: translateX(1px);
  }
  .blog-list {
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
    padding: 0 15px;
  }
  .logo-image {
    width: 150px;
    height: auto;
  }
  .search-container {
    position: static;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    left: auto; /* на всякий случай сбрасываем */
    top: auto;  /* сбрасываем */
  }
  .search-bar {
    width: 20%;
    font-size: 14px; /* при необходимости */
  }

  .language-switcher {
    top: 10px;
    left: 10px;
  }
  .language-switcher button {
    font-size: 10px;
    padding: 3px 5px;
  }
}
</style>
