<template>
  <div class="login-page">
    <h1>Admin Login</h1>
    <form @submit.prevent="login">
      <input type="email" v-model="email" placeholder="Email" required />
      <input type="password" v-model="password" placeholder="Password" required />
      <button type="submit">Login</button>
      <p v-if="error" class="error">{{ error }}</p>
    </form>
  </div>
</template>

<script>
import { auth } from "@/firebaseConfig"; // Firebase auth
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";

export default {
  data() {
    return {
      email: "",
      password: "",
      error: "",
    };
  },
  methods: {
    async login() {
      try {
        // Аутентификация через Firebase
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;

        // Проверка роли в Firestore
        const db = getFirestore();
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists() && userDoc.data().role === "admin") {
          this.$router.push("/admin-dashboard") ; // ✅ Переход в админку
        } else {
          await signOut(auth); // ❌ Разлогиниваем, если не админ
          this.error = "Access Denied: You are not an admin.";
        }
      } catch (error) {
        this.error = "Invalid email or password.";
      }
    },
  },
};
</script>

<style scoped>
.login-page {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

input {
  display: block;
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  display: block;
  width: calc(100% - 20px);
  padding: 10px;
  margin: 20px auto;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #555;
}

.error {
  margin-top: 10px;
  color: red;
  font-size: 14px;
}
</style>
