import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC7kw-PCUMg3rsBbnz_vTpeQ9SnwL-UIbs",
  authDomain: "modelssite-38213.firebaseapp.com",
  projectId: "modelssite-38213",
  storageBucket: "modelssite-38213.firebasestorage.app",
  messagingSenderId: "451305641240",
  appId: "1:451305641240:web:01ea83eb4efe1e3ba8ba6d"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

console.log("Firebase initialized:", app);
export { auth, db, storage };


