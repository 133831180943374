<template>
  <div class="blog-details" v-if="blog">
    <!-- Заголовок с обработчиком клика -->
    <h1 class="blog-title" @click="goToModelPage">{{ blog.title }}</h1>
    <h2 class="blog-subtitle">{{ blog.subtitle }}</h2>

    <!-- Сетка фотографий -->
    <div class="photos-grid">
      <img v-for="(image, index) in blog.imageUrls" :key="index" :src="image" :alt="`Image ${index + 1}`" />
    </div>

    <p class="blog-content">{{ blog.content }}</p>
  </div>
  <div v-else>
  </div>
</template>

<script>
import { db } from "@/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

export default {
  data() {
    return {
      blog: null
    };
  },
  async created() {
    try {
      const docRef = doc(db, "blogPosts", this.$route.params.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        this.blog = docSnap.data();
      } else {
        console.error("Документ не найден");
      }
    } catch (error) {
      console.error("Ошибка при получении записи блога:", error);
    }
  },
  methods: {
    goToModelPage() {
      // Предполагаем, что в объекте блога есть поле modelId, связанное с моделью
      if (this.blog && this.blog.model && this.blog.model.id) {
        this.$router.push(`/models/${this.blog.model.id}`); // Перенаправляем на страницу модели
      } else {
        console.error("У этой записи блога нет связанной модели.");
      }
    },
  },
};
</script>


<style scoped>
/* Стиль для основного контейнера */
.blog-details {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
}

/* Заголовки */
.blog-title {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 5px;
  text-align: center;
  color: #333;
  cursor: pointer; /* Курсор в виде руки */
  transition: color 0.3s ease;
}

.blog-title:hover {
  color: #717070; /* Подсветка при наведении */
}

.blog-subtitle {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px;
  text-align: center;
  color: #666;
}

/* Сетка фотографий */
.photos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.photos-grid img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.photos-grid img:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Текстовое содержимое */
.blog-content {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  margin-top: 20px;
  text-align: justify;
  color: #444;
}
</style>
