import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import BlogPage from '../views/BlogPage.vue';
import ModelsPage from '../views/ModelsPage.vue';
import InstagramPage from '../views/InstagramPage.vue';
import BeDiscoveredPage from '../views/BeDiscoveredPage.vue';
import ContactPage from '../views/ContactPage.vue';
import ModelDetails from '@/components/ModelDetails.vue';
import AdminLogin from '@/views/AdminLogin.vue';
import AdminDashboard from '@/views/AdminDashboard.vue';
import BlogManager from '@/components/BlogManager.vue';
//import { Auth } from "firebase/auth";
import BlogDetails from "@/views/BlogDetails.vue";
import BlogForm from '@/components/BlogForm.vue';
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
const routes = [
  
    { path: '/', name: 'Home', component: HomePage },
    { path: '/blog', name: 'Blog', component: BlogPage },
    { path: '/models', name: 'Models', component: ModelsPage },
    { path: '/models/:id', name: 'ModelDetails', component: ModelDetails },
    { path: '/instagram', name: 'Instagram', component: InstagramPage },
    { path: '/be-discovered', name: 'BeDiscovered', component: BeDiscoveredPage },
    { path: '/contact', name: 'Contact', component: ContactPage },
    { path: '/admin-login', name: 'AdminLogin', component: AdminLogin },
    { path: '/admin-dashboard', name: 'AdminDashboard', component: AdminDashboard, meta: { requiresAuth: true } },
    { path: '/blog-manager', name: 'BlogManager', component: BlogManager, meta: { requiresAuth: true } },
    { path: '/blog/:id', name: 'BlogDetails', component: BlogDetails, },
    { path: '/blog-form', name: 'BlogForm', component: BlogForm, }
  ];


const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(async (to, from, next) => {
  const auth = getAuth();
  const user = auth.currentUser;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);

  if (requiresAuth) {
    if (!user) {
      // If not authenticated, redirect to login page
      next({ name: 'AdminLogin' });
    } else if (requiresAdmin) {
      // Check if the user is an admin
      const db = getFirestore();
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists() && userDoc.data().role === 'admin') {
        // If the user is an admin, proceed
        next();
      } else {
        // If not an admin, redirect to login page
        next({ name: 'AdminLogin' });
      }
    } else {
      // If authenticated and does not require admin, proceed
      next();
    }
  } else {
    // If no authentication required, proceed
    next();
  }
});
export default router;