<template>
  <div class="dashboard">
    <h1>Admin Dashboard</h1>
    <BlogManager />
    <button @click="logout">Logout</button>
  </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import BlogManager from "@/components/BlogManager.vue";

export default {
  components: {
    BlogManager,
  },
  methods: {
    logout() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.$router.push("/admin-login");
      });
    },
  },
};
</script>

<style scoped>
.dashboard {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
  background-color: #f4f4f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

h1 {
  margin-bottom: 20px;
  font-size: 28px;
  color: #333;
}

button {
  display: block;
  width: calc(100% - 20px);
  padding: 12px 24px;
  margin: 20px auto;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:focus {
  outline: none;
}

button:active {
  background-color: #003f7f;
}
</style>
